.title {
  font-size: 24px;
  text-align: center;
}

.outerBar {
  position: relative;
  width: 100%;
  max-width: 600px;
  height: 48px;
  border-radius: 4px;
  background-color: var(--lightgrey-color);
  overflow: hidden;
  margin-top: 32px;
}

.innerBar {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  background-color: var(--green-color);
  transition: 1s ease;
  transition-delay: 0.5s;
}
