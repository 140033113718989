.container {
  display: flex;
  align-items: center;
  font-size: 12px;
}

.selectLabel {
  margin: 12px;
  color: var(--darkgrey-color);
}

.item {
  margin: 12px;
  color: var(--darkgrey-color);
  cursor: pointer;
}

.item:hover {
  opacity: 0.8;
}

.selected {
  background-color: var(--lightgrey-color);
  margin: 8px;
  padding: 4px;
  border-radius: 4px;
}
