.content {
  padding: 16px;
}

.name {
  padding: 16px;
}

.submit {
  margin: 16px;
}
