:root {
  --bg-color: #f5f5f5;
  --black-color: #000000;
  --darkgrey-color: #333333;
  --green-color: #009933;
  --grey-color: #999999;
  --lightgrey-color: #eeeeee;
  --red-color: #ff4433;
  --white-color: #ffffff;

  --toastify-color-success: var(--green-color) !important;
  --toastify-color-error: var(--red-color) !important;
  --toastify-font-family: Roboto, sans-serif !important;
}
