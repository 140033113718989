.container {
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.container:hover {
  opacity: 0.8;
}

.container:active .icon {
  transform: scale(0.95);
}

.disabled,
.disabled:hover {
  opacity: 0.3;
  cursor: default;
}

.disabled:active .icon {
  transform: none;
}

.icon {
  min-width: 24px;
  min-height: 24px;
}

.label {
  margin-left: 8px;
}
