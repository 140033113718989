.container {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 32px 0;
}

.title {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 16px;
}

.item {
  display: flex;
  align-items: center;
  margin: 16px;
  max-width: 400px;
  width: 100%;
}

.step {
  font-weight: 700;
  margin-bottom: 8px;
}

.icon {
  width: 120px;
  height: 120px;
  margin-right: 32px;
}
