.info {
  text-align: center;
  padding: 32px;
}

.emptyFiltered {
  border-top: 1px solid var(--lightgrey-color);
  text-align: center;
  padding: 32px;
}

.footer {
  position: sticky;
  bottom: -1px; /* Hack to enable stuck behaviour. */
  left: 0;
  right: 0;
  border-top: 1px solid var(--lightgrey-color);
  background-color: var(--white-color);
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  padding-bottom: 1px; /* Due to -1px bottom. */
  transition: all 0.3s;
}

.footerStuck {
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.2);
  border-top: none;
  border-radius: 4px;
  margin: 24px;
}

@media (max-width: 600px) {
  .footerStuck {
    margin: 0;
  }
}
