.container {
  max-width: 800px;
  width: 100%;
}

.content {
  display: flex;
  flex-direction: column;
  background-color: var(--white-color);
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  width: 100%;
}

@media (max-width: 600px) {
  .content {
    box-shadow: none;
  }
}
