.container {
  flex: 1;
  height: 32px;
  border: 2px solid var(--green-color);
  border-radius: 16px;
  margin-left: 16px;
}

.progress {
  position: relative;
  margin: 2px;
  height: 24px;
  overflow: hidden;
  border-radius: 12px;
}

.paidBar {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  background-color: var(--green-color);
  border-radius: 12px;
  transition: width 0.5s ease-out;
}

.lockedBar {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(5, 196, 107, 0.4); /* 40% green */
  transition: width 0.5s ease-out;
  border-radius: 12px;
}
