.container {
  display: inline-flex;
  user-select: none;
  transition: opacity 0.3s;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.button {
  align-items: center;
  padding: 8px 12px;
  color: var(--white-color);
  border-radius: 4px;
}

.clickable {
  cursor: pointer;
}

.clickable:hover {
  opacity: 0.8;
}

.clickable:active {
  transform: scale(0.95);
}

.disabled {
  opacity: 0.3;
}

.loader {
  margin-right: 8px;
}

.image {
  width: 16px;
  height: 16px;
  margin-right: 8px;
}

.icon {
  min-width: 16px;
  min-height: 16px;
  margin-right: 8px;
}
