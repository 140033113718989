.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px;
}

.total {
  flex: 1;
  margin-right: 16px;
}

.tip {
  font-size: 12px;
  margin-top: 4px;
}

@media (max-width: 600px) {
  .container {
    padding: 16px;
  }
}
