html,
body {
  padding: 0;
  margin: 0;
}

html,
body,
input,
select,
textarea {
  font: normal 16px Roboto, sans-serif;
  background-color: var(--bg-color);
}

a {
  color: var(--darkgrey-color);
}

a:hover {
  opacity: 0.8;
}

* {
  box-sizing: border-box;
}
