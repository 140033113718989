.diff {
  white-space: nowrap;
}

.positive {
  color: var(--green-color);
}

.negative {
  color: var(--red-color);
}
