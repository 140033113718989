.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px;
}

.fullscreen {
  min-height: 100vh;
  justify-content: center;
}

@media (max-width: 600px) {
  .container {
    padding: 0;
  }

  .fullscreen {
    padding: 16px;
  }
}
