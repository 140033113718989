.container {
  font-size: 32px;
  text-align: center;
  margin-bottom: 64px;
}

.title {
  font-size: 32px;
  font-weight: 700;
  color: var(--green-color);
}

.subtitle {
  font-size: 24px;
  margin-top: 32px;
}

.info {
  font-size: 24px;
}

@media (max-width: 600px) {
  .title {
    font-size: 24px;
  }

  .subtitle {
    font-size: 16px;
    margin-top: 16px;
  }

  .info {
    font-size: 24px;
  }
}
