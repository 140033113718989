.container {
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.item {
  margin: 0 8px;
  padding: 12px 16px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  cursor: pointer;
  font-weight: 700;
  text-decoration: none;
  color: var(--black-color);
}

.item:not(.selected):hover {
  opacity: 0.8;
}

.selected {
  position: relative;
  background-color: var(--white-color);
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
}

.item.selected::after {
  content: "";
  position: absolute;
  bottom: -8px;
  left: 0;
  right: 0;
  height: 8px;
  background-color: var(--white-color);
}

@media (max-width: 600px) {
  .container {
    margin-top: 24px;
  }

  .selected {
    box-shadow: none;
  }

  .item.selected::after {
    display: none;
  }
}
