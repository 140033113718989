.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 24px;
}

.info {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px;
  line-height: 1.5;
  color: var(--grey-color);
  min-height: 112px; /* Same height as when products are selected. */
}

.icon {
  min-width: 32px;
  min-height: 32px;
  margin-right: 8px;
}

.highlight {
  animation: pulse 1s;
}

@media (max-width: 600px) {
  .container {
    padding: 16px;
  }

  .info {
    padding: 16px;
    min-height: 80px;
  }
}

@keyframes pulse {
  0% {
    opacity: 0;
    transform: scale(1.05);
  }

  100% {
    opacity: 1;
  }
}
