.container {
  width: 100%;
  max-width: 500px;
  border-radius: 8px;
  background-color: var(--white-color);
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
  margin: 32px;
  outline: none;
  max-height: calc(100% - 64px);
  overflow-y: auto;
  animation: zoomIn 0.5s;
}

.overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.7);
  animation: fadeIn 0.5s;
}

@keyframes zoomIn {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}
