.container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 32px 0;
}

.createLink {
  display: inline-flex;
  margin: 12px;
  text-decoration: none;
  text-align: center;
}

.logo {
  width: 64px;
  height: auto;
  margin: 16px;
}

.separator {
  height: 1px;
  background-color: var(--grey-color);
  width: auto;
  margin: 4px 12px;
}

@media (max-width: 600px) {
  .container {
    flex-direction: column-reverse;
    align-items: center;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .separator {
    align-self: stretch;
    margin: 16px 0;
  }

  .logo {
    margin: 32px;
  }
}
