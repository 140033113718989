.container {
  font-size: 32px;
  text-align: center;
  margin-bottom: 64px;
}

@media (max-width: 600px) {
  .container {
    font-size: 24px;
  }
}
