.container {
  padding: 16px;
}

.warning {
  display: flex;
  align-items: center;
  padding: 16px;
  color: var(--red-color);
}

.warningIcon {
  margin-right: 8px;
  min-width: 24px;
  min-height: 24px;
}

.row {
  display: flex;
}

.item {
  flex: 1;
  padding: 16px;
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
}

@media (max-width: 600px) {
  .item {
    padding: 8px;
  }

  .buttons {
    padding: 16px 8px 8px;
  }
}
