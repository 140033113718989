.container {
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--lightgrey-color);
  padding: 8px;
}

.container:hover {
  background-color: rgba(0, 0, 0, 0.02);
}

.highlight {
  animation: successAnimation 4s;
}

.container:last-child {
  border: none;
}

.name,
.sum {
  flex: 1;
  padding: 16px;
}

.tooltipWrapper {
  display: inline-flex;
  align-items: center;
}

.icon {
  min-width: 16px;
  min-height: 16px;
  margin-left: 4px;
}

.products {
  flex: 2;
  padding: 16px;
}

@media (max-width: 600px) {
  .container {
    flex-wrap: wrap;
    padding: 16px;
  }

  .container:hover {
    background-color: transparent;
  }

  .name {
    width: 60%;
  }

  .sum {
    width: 40%;
    text-align: right;
    white-space: nowrap;
  }

  .products {
    width: 100%;
  }

  .name,
  .sum,
  .products {
    flex: none;
    padding: 8px;
  }
}

@keyframes successAnimation {
  from {
    background-color: var(--green-color);
  }
  to {
    background-color: var(--white-color);
  }
}
