.button {
  padding: 12px;
  animation: slideIn 0.5s;
}

@keyframes slideIn {
  0% {
    opacity: 0;
    transform: translateX(-80px) skewX(30deg);
  }

  80% {
    opacity: 1;
    transform: translateX(4px);
  }

  100% {
    transform: translateX(0);
  }
}
