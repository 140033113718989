.container {
  display: flex;
  align-items: center;
  padding: 8px;
  user-select: none;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.container:hover {
  background-color: rgba(0, 0, 0, 0.02);
}

.disabled {
  opacity: 0.3;
  cursor: default;
}

.separator {
  background-color: var(--lightgrey-color);
  height: 1px;
}

.icons {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
}

.placeholder {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border: 1px solid var(--black-color);
  border-radius: 16px;
  background-color: var(--white-color);
  margin-right: 16px;
}

@media (max-width: 600px) {
  .container:hover {
    background-color: transparent;
  }
}
