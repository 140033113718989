.container {
  padding: 16px;
  user-select: none;
}

.label {
  margin: 16px;
  font-size: 24px;
  text-align: center;
}

.items {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 8px;
}

.item {
  margin: 8px;
}
