.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.title {
  font-size: 32px;
  font-weight: 700;
  text-align: center;
  margin-top: 32px;
}

.subtitle {
  margin-top: 16px;
  text-align: center;
  line-height: 1.5;
}

.buttons {
  display: flex;
  align-items: center;
  margin: 16px;
}

@media (max-width: 600px) {
  .buttons {
    flex-direction: column;
    margin: 8px;
  }
}
