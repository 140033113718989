.container {
  padding: 16px;
}

.row {
  display: flex;
}

.item {
  flex: 1;
  padding: 16px;
}

.button {
  margin: 16px;
}

@media (max-width: 600px) {
  .item {
    padding: 8px;
  }

  .button {
    margin: 16px 8px 8px;
  }
}
