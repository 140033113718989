.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px;
}

.title {
  text-align: center;
  margin-bottom: 32px;
  font-size: 24px;
}

.buttons {
  display: flex;
  margin-top: 16px;
}

.button {
  margin: 16px;
}
