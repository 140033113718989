.container {
  flex: 1;
  display: flex;
  align-items: center;
  padding: 16px;
  min-width: 120px;
}

.warningContainer {
  display: flex;
  align-items: center;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.warningContainer:hover {
  opacity: 0.8;
}

.alertIcon {
  color: var(--red-color);
  min-width: 32px;
  min-height: 32px;
  margin-right: 8px;
  outline: none;
}

.details {
  display: flex;
  align-items: center;
  margin-top: 4px;
  font-size: 12px;
}

.separator {
  margin: 0 8px;
  width: 2px;
  height: 2px;
  border-radius: 1px;
  background-color: var(--grey-color);
}

@media (max-width: 600px) {
  .container {
    padding: 8px;
  }

  .alertIcon {
    width: 24px;
    height: 24px;
  }
}
