.container {
  min-width: 0; /* To make sure truncate works. */
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.label {
  margin-bottom: 8px;
}

.input {
  width: 100%;
  background-color: var(--bg-color);
  outline: none;
  border: none;
  padding: 8px;
  border-radius: 4px;
}

.error {
  border: 2px solid var(--red-color);
  background-color: rgba(231, 76, 60, 0.2); /* 20% red */
  padding: 6px;
}
