.container {
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--lightgrey-color);
}

.name,
.total {
  flex: 1;
  padding: 16px;
  color: var(--grey-color);
}

.products {
  flex: 2;
  padding: 16px;
  color: var(--grey-color);
}

@media (max-width: 600px) {
  .container {
    display: none;
  }
}
