.container {
  height: 64px;
  width: 64px;
  position: relative;
  user-select: none;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.container:active {
  transform: scale(0.95);
}

.icon {
  width: 100%;
  height: 100%;
}

.percent {
  position: absolute;
  top: 4px;
  right: 4px;
  background-color: var(--white-color);
  border: 1px solid var(--black-color);
  font-size: 10px;
  font-weight: 700;
  padding: 2px 4px;
  border-radius: 8px;
}

@media (max-width: 600px) {
  .container {
    height: 48px;
    width: 48px;
  }
}
