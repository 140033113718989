.container {
  max-width: 500px;
  padding: 32px;
}

.title {
  font-size: 24px;
  text-align: center;
  margin-bottom: 32px;
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: -8px;
}

.button {
  margin: 8px;
}

@media (max-width: 600px) {
  .title {
    font-size: 20px;
  }
}
