.container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  border-top: 1px solid var(--lightgrey-color);
  padding: 16px 0;
}

.qrCode {
  display: block;
  margin: 32px auto;
}

.qrCodeInfo {
  text-align: center;
  margin: 32px;
}

@media (max-width: 600px) {
  .container {
    font-size: 12px;
  }
}
